import {
  CalendarIcon,
  GlobeIcon,
  LinkedInOutlinedIcon,
  MapPinIcon,
  UsersIcon,
} from '@gain/components/icons'
import { Investor } from '@gain/rpc/app-model'
import { formatCountryAndCity } from '@gain/utils/countries'
import { formatFte } from '@gain/utils/financials'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import React from 'react'

import Card, { CardContent, CardHeader } from '../../../../common/card/card'
import ListItemIconValue from '../../../../common/list/list-item-icon-value'
import ListItemIconValueLink from '../../../../common/list/list-item-icon-value-link'

export interface InvestorOrganisationCardProps {
  investor: Investor
}

export default function InvestorOrganisationCard({ investor }: InvestorOrganisationCardProps) {
  const latestFinancialResultWithFte = investor.fteMeasurements?.slice().pop()

  return (
    <Card fullHeight>
      <CardHeader title={'Organisation'} />
      <CardContent>
        <List
          dense
          disablePadding>
          {investor.operationalHqCountryCode && (
            <ListItemIconValue
              icon={MapPinIcon}
              tooltip={'Operational HQ'}>
              {formatCountryAndCity(investor.operationalHqCountryCode, investor.operationalHqCity)}
            </ListItemIconValue>
          )}

          {investor.yearFounded && (
            <ListItemIconValue
              icon={CalendarIcon}
              tooltip={'Year founded'}>
              {investor.yearFounded}
            </ListItemIconValue>
          )}

          {latestFinancialResultWithFte && (
            <ListItemIconValue
              icon={UsersIcon}
              tooltip={'Total FTEs'}>
              {formatFte(latestFinancialResultWithFte.employeeCount, false)}
            </ListItemIconValue>
          )}

          {investor.url && (
            <ListItemIconValue icon={GlobeIcon}>
              <ListItemIconValueLink url={investor.url} />
            </ListItemIconValue>
          )}

          {investor.linkedInUrl && (
            <ListItemIconValue icon={LinkedInOutlinedIcon}>
              <ListItemIconValueLink url={investor.linkedInUrl} />
            </ListItemIconValue>
          )}
        </List>

        {!!investor.foundedBy?.length && (
          <Typography
            sx={{ mt: 2 }}
            variant={'body2'}>
            Founded by: {investor.foundedBy}
          </Typography>
        )}
      </CardContent>
    </Card>
  )
}
