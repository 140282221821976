import { useOnMountEffect } from '@gain/utils/react'
import { useState } from 'react'

/**
 * Due to the bug https://bugs.chromium.org/p/chromium/issues/detail?id=678075,
 * it's not reliable to detect if the browser is currently online or offline
 * based on `navigator.onLine`.
 * As a workaround, we always assume it's online on the first load, and change
 * the status upon `online` or `offline` events.
 *
 * Copied over from:
 * https://github.com/vercel/swr/blob/1585a3e37d90ad0df8097b099db38f1afb43c95d/src/_internal/utils/web-preset.ts#L4
 */
export function useHasInternet() {
  const [hasInternet, setHasInternet] = useState(true)

  // Attach offline and maintenance event listeners
  useOnMountEffect(() => {
    window.addEventListener('online', () => {
      setHasInternet(true)
    })

    window.addEventListener('offline', () => {
      setHasInternet(false)
    })
  })

  return hasInternet
}
