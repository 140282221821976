import { styled } from '@mui/material/styles'
import { svgIconClasses } from '@mui/material/SvgIcon'
import { ComponentType } from 'react'

const StyledKbd = styled('kbd')(({ theme }) => ({
  boxShadow: theme.palette.shadow.level1d,
  border: `1px solid ${theme.palette.grey[200]}`,
  display: 'inline-flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  backgroundColor: 'transparent',
  padding: theme.spacing(0.5, 1),
  borderRadius: 5,
  fontSize: '12px',
  fontFamily: theme.typography.fontFamily,

  [`& .${svgIconClasses.root}`]: {
    color: theme.palette.grey[700],
    fontSize: '14px',
  },
}))

interface KbdProps {
  icon?: ComponentType
  label: string
}

/**
 * The Kbd component is used to highlight keyboard, mouse, or any other device
 * input. Examples:
 *  <Kbd label={'Click'} />
 *  <Kbd icon={ShiftIcon} label={'Shift'} />
 */
export default function Kbd({ icon: Icon, label }: KbdProps) {
  return (
    <StyledKbd>
      {Icon && <Icon />}
      {label}
    </StyledKbd>
  )
}
