import { RowType } from '@gain/utils/table'
import { UIEvent, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { VirtualTableApi } from './use-virtual-table-api'

export default function useVirtualScrollPositionApi<Row extends RowType>(
  api: VirtualTableApi<Row>
) {
  const lastScrollPositionRef = useRef(0)
  const { key } = useLocation()
  const storageKey = `${key}-scroll-position`

  useEffect(() => {
    const lastKnownScrollPosition = sessionStorage.getItem(storageKey)

    // Set back the last know scroll position for this route
    if (lastKnownScrollPosition && api.containerRef.current) {
      api.containerRef.current?.scroll({
        top: parseInt(lastKnownScrollPosition, 10),
        behavior: 'smooth',
      })
    }

    return () => {
      // Store the last scroll position
      sessionStorage.setItem(storageKey, `${lastScrollPositionRef.current}`)
    }
  }, [api.containerRef, storageKey])

  return {
    onScroll: (event: UIEvent<HTMLDivElement>) => {
      lastScrollPositionRef.current =
        event?.currentTarget?.scrollTop ?? lastScrollPositionRef.current
    },
    resetScroll: () => {
      sessionStorage.removeItem(storageKey)

      if (api.containerRef.current && lastScrollPositionRef.current !== 0) {
        api.containerRef.current.scroll({ top: 0 })
      }
    },
  }
}
