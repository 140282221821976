import { AssetListItem } from '@gain/rpc/app-model'
import { ListSort } from '@gain/rpc/list-model'
import { uniq } from 'lodash'
import { useMemo } from 'react'

import { ColumnConfig } from '../../../common/virtual-table'
import { ASSET_FILTER_MAP, AssetFilterField } from '../../asset/asset-filter-bar'
import { flattenFilters, fromFilterModel, useFilterModelQueryParam } from '../../filter/filter-bar'
import { LIST_VIEW_ASSET_DEFAULT_SORT } from './asset-list-view'

const activeFilterToSortingColumn: Array<keyof AssetListItem> = ['relevanceRank']

// useActiveFilterSorting set the default sorting for the first column that matches the active filters using its
// sortFields or the column field itself of the found columns
// this is done because depending on which filters are active, different columns are shown, see 'Similar to' filter
export default function useActiveAssetFilterSorting(
  columns: ColumnConfig<AssetListItem>[]
): ListSort<AssetListItem>[] | undefined {
  const [filterModel] = useFilterModelQueryParam<AssetListItem, AssetFilterField>()

  return useMemo(() => {
    const filters = fromFilterModel(filterModel, ASSET_FILTER_MAP)
    const filterKeys = uniq(flattenFilters(filters))

    if (filterKeys.length === 0) {
      return LIST_VIEW_ASSET_DEFAULT_SORT
    }

    const column = columns.find(({ field }) => activeFilterToSortingColumn.includes(field))
    if (!column) {
      return LIST_VIEW_ASSET_DEFAULT_SORT
    }

    const sortFields = column.sortFields ? column.sortFields : [column.field]
    return sortFields.map((sortField) => ({
      direction: column.defaultSortDirection || 'desc',
      field: sortField,
    }))
  }, [columns, filterModel])
}
