import { Asset } from '@gain/rpc/app-model'

import Card, { CardHeader } from '../../../common/card/card'
import { CardAssetFteTabs } from './card-asset-fte-tabs'

export interface CardAssetFteProps {
  asset: Asset
  showDivider?: boolean
}

export default function CardAssetFte({ asset, showDivider }: CardAssetFteProps) {
  return (
    <Card
      sx={{ minHeight: 398 }}
      fullHeight>
      <CardHeader title={'FTEs'} />
      <CardAssetFteTabs
        asset={asset}
        showDivider={showDivider}
      />
    </Card>
  )
}
