import Flag from '@gain/components/flag'
import { AssetListItem } from '@gain/rpc/app-model'
import { useSplitList } from '@gain/utils/list'
import { ColumnVisibilityModel, useVisibleColumns } from '@gain/utils/table'
import Stack from '@mui/material/Stack'
import React, { useMemo, useRef, useState } from 'react'

import ButtonMore from '../../common/button-more'
import { ContentLinkCellContainer } from '../../common/content-link'
import VirtualTable, { createVirtualTableColumns, VirtualSort } from '../../common/virtual-table'
import AssetContentLink from '../asset/asset-content-link'
import MultipleTableCell from '../asset/asset-list-item-table/table-cell/multiple-table-cell'
import useSortedAssets from '../benchmarking/use-sorted-assets'
import FinancialValue from '../financial/financial-value'

function useColumns(similarToAssetName?: string) {
  return useMemo(() => {
    return createVirtualTableColumns<AssetListItem>(
      {
        headerName: 'Name',
        field: 'name',
        width: 208,
        flex: 1,
        defaultSortDirection: 'asc',
        renderCell: ({ row }) => (
          <ContentLinkCellContainer>
            <AssetContentLink
              asset={row}
              id={row.id}
              logoFileUrl={row.logoFileUrl}
              name={row.name}
            />
          </ContentLinkCellContainer>
        ),
      },
      {
        field: 'description',
        headerName: 'Business description',
        width: 100,
        flex: 1,
        sortable: false,
        valueFormatter: ({ value }) => value || '-',
      },
      // Don't show the relevance rank if the data is not filtered by a similar company
      !!similarToAssetName && {
        field: 'relevanceRank',
        headerName: 'Similar rank',
        headerExplainer: `The rank of how similar a public company is to ${similarToAssetName} when compared to all companies in our database.`,
        align: 'right',
        width: 138,
        defaultSortDirection: 'asc',
        valueFormatter: ({ value }) => `#${value}`,
      },
      {
        field: 'region',
        headerName: 'HQ',
        align: 'center',
        width: 56,
        renderCell: ({ value }) => <Flag code={value} />,
      },
      {
        field: 'enterpriseValueEur',
        headerName: 'Enterprise value',
        align: 'right',
        width: 160,
        renderCell: ({ value, row }) => (
          <FinancialValue
            amount={value}
            year={row.enterpriseValueFiscalYear}
          />
        ),
      },
      {
        field: 'enterpriseValueEbitdaRatio',
        headerName: 'EV / EBITDA',
        align: 'right',
        width: 136,
        renderCell: ({ value, row }) => (
          <MultipleTableCell
            precision={1}
            value={value}
            year={row.enterpriseValueEbitdaRatioFiscalYear}
            enableNotMeaningful
          />
        ),
      },
      {
        field: 'enterpriseValueEbitRatio',
        headerName: 'EV / EBIT',
        align: 'right',
        width: 136,
        renderCell: ({ value, row }) => (
          <MultipleTableCell
            precision={1}
            value={value}
            year={row.enterpriseValueEbitRatioFiscalYear}
            enableNotMeaningful
          />
        ),
      },
      {
        field: 'enterpriseValueRevenueRatio',
        headerName: 'EV / Sales',
        align: 'right',
        width: 136,
        renderCell: ({ value, row }) => (
          <MultipleTableCell
            precision={1}
            value={value}
            year={row.enterpriseValueRevenueRatioFiscalYear}
            enableNotMeaningful
          />
        ),
      }
    )
  }, [similarToAssetName])
}

const columnVisibility: ColumnVisibilityModel<AssetListItem> = {
  relevanceRank: 1100,
  description: 960,
  region: 1200,
}

interface AssetValuationTableProps {
  assets: AssetListItem[]
  similarToAssetName?: string // Enables the relevance rank column when set
  initialSort: VirtualSort<AssetListItem>[]
}

const EMPTY_ARRAY = []

export default function AssetValuationTable({
  assets,
  similarToAssetName,
  initialSort,
}: AssetValuationTableProps) {
  const tableRef = useRef<HTMLDivElement>(null)
  const columns = useColumns(similarToAssetName)
  const visibleColumns = useVisibleColumns(tableRef, columns, columnVisibility)

  const [sortedAssets, sort, onSort] = useSortedAssets(
    assets,
    // TODO: Replace the EMPTY_ARRAY's with actual selected and added asset id's
    //  when this functionality is added in the next ticket
    EMPTY_ARRAY,
    EMPTY_ARRAY,
    initialSort
  )

  const [initialRows, otherRows] = useSplitList(sortedAssets, 10)
  const [showAll, setShowAll] = useState(!!otherRows.length)

  return (
    <Stack ref={tableRef}>
      <VirtualTable
        columns={visibleColumns}
        onSort={onSort}
        rows={!showAll ? initialRows : sortedAssets}
        sort={sort}
        variant={'inline'}
      />
      {!showAll && (
        <ButtonMore
          amount={otherRows.length}
          onClick={() => setShowAll(true)}
          variant={'chevron-down'}
        />
      )}
    </Stack>
  )
}
