import { Asset, AssetListItem } from '@gain/rpc/app-model'
import { ListSort } from '@gain/rpc/list-model'
import { AssetOwnershipType } from '@gain/rpc/shared-model'
import { listFilter, listFilters, listSort } from '@gain/rpc/utils'
import React, { useMemo } from 'react'

import ValuationPage from '../../../features/valuation/valuation-page'

const initialSort = new Array<ListSort<AssetListItem>>(listSort('relevanceRank', 'asc'))

interface PrivateAssetValuationProps {
  asset: Asset
}

export function PrivateAssetValuation({ asset }: PrivateAssetValuationProps) {
  const filters = useMemo(() => {
    // Select listed companies similar to the given asset ID that have at least
    // one of the following ratios: EV/EBITDA, EV/EBIT, EV/Sales
    return listFilters<AssetListItem>(
      listFilter('relevanceRank', '=', asset.id),
      listFilter('ownership', '=', AssetOwnershipType.Listed),
      listFilter(
        '',
        'or',
        listFilters(
          listFilter('enterpriseValueEbitdaRatio', '>', 0),
          listFilter('enterpriseValueEbitRatio', '>', 0),
          listFilter('enterpriseValueRevenueRatio', '>', 0)
        )
      )
    )
  }, [asset.id])

  return (
    <ValuationPage
      filter={filters}
      limit={10}
      listedAssetsCardTitle={'Similar public companies'}
      similarToAssetName={asset.generalInfo?.name}
      sort={initialSort}
    />
  )
}
