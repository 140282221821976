import { generatePath } from 'react-router-dom'

import { CONFERENCE_PAGE_PATH, Params } from '../utils'

export enum RouteConferenceTabs {
  Summary = 'summary',
  Exhibitors = 'exhibitors',
  Investors = 'investors',
  Deals = 'deals',
  Analysis = 'analysis',
  Benchmarking = 'benchmarking',
  Valuation = 'valuation',
}

export interface ConferencePageParams extends Params {
  conferenceId: string
  conferenceName?: string
}

export const CONFERENCE_SUMMARY_PATH = `${CONFERENCE_PAGE_PATH}/${RouteConferenceTabs.Summary}`
export const CONFERENCE_EXHIBITORS_PATH = `${CONFERENCE_PAGE_PATH}/${RouteConferenceTabs.Exhibitors}`
export const CONFERENCE_INVESTORS_PATH = `${CONFERENCE_PAGE_PATH}/${RouteConferenceTabs.Investors}`
export const CONFERENCE_DEALS_PATH = `${CONFERENCE_PAGE_PATH}/${RouteConferenceTabs.Deals}`
export const CONFERENCE_ANALYSIS_PATH = `${CONFERENCE_PAGE_PATH}/${RouteConferenceTabs.Analysis}`
export const CONFERENCE_BENCHMARKING_PATH = `${CONFERENCE_PAGE_PATH}/${RouteConferenceTabs.Benchmarking}`
export const CONFERENCE_VALUATION_PATH = `${CONFERENCE_PAGE_PATH}/${RouteConferenceTabs.Valuation}`

interface GenerateConferencePagePathParams {
  conferenceId: string | number
  conferenceName?: string
}

export function generateConferencePagePath(params: GenerateConferencePagePathParams) {
  return generatePath(CONFERENCE_SUMMARY_PATH, params)
}
