import { isDefined } from '@gain/utils/common'
import { get } from 'lodash'
import React, { ReactElement, ReactNode } from 'react'

// KeyValueData represents the data that can be passed to the KeyValueGrid.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type KeyValueData = Record<string, any> | undefined

export interface Key<Data extends KeyValueData> {
  field: string // If set and the data value is empty the key will not be rendered
  label?: string | ReactElement
  valueFormatter?: (data: Data) => string | undefined | boolean
  renderLabel?: (data: Data) => string
  renderValue?: (data: Data) => string | React.ReactNode
}

export interface KeyValue {
  field?: string
  label?: string | ReactElement
  value?: string
  valueNode?: React.ReactNode
}

export function getKeyValue<Data extends KeyValueData>(
  data: Data,
  key: Key<Data>
): KeyValue | undefined {
  let originalKeyValue: unknown
  if (key.field) {
    // If the value is empty there's nothing to render
    originalKeyValue = get<Data, string, string | unknown>(data, key.field, null)
    if (!isDefined(originalKeyValue)) {
      return undefined
    }
  }

  let keyValue: unknown = originalKeyValue
  let keyNode: ReactNode = null

  const keyLabel = key.renderLabel ? key.renderLabel(data) : key.label

  if (key.valueFormatter) {
    keyValue = key.valueFormatter(data)
  } else if (key.renderValue) {
    keyValue = null
    keyNode = key.renderValue(data)
  }

  if (keyValue || keyNode) {
    return {
      field: key.field,
      label: keyLabel,
      value: keyValue as string,
      valueNode: keyNode,
    }
  }

  return undefined
}
