import { CurrencyListItem } from '@gain/rpc/app-model'
import {
  FinancialResult,
  formatFte,
  useDefinedAmountTypes,
  useDefinedFinancials,
} from '@gain/utils/financials'
import { formatNumber } from '@gain/utils/number'
import { useMemo } from 'react'

import FinancialResultsTable from '../financial-results-table/financial-results-table'
import { useLastReportedFinancialYearIndex } from '../financial-results-table/financial-results-table-hooks'
import {
  formatAmount,
  formatGrowth,
  formatPercentageOfRevenue,
  RowGroupConfig,
} from '../financial-results-table/financial-results-table-utils'

function useRowGroups(financialResults: FinancialResult[]) {
  const used = useDefinedFinancials(financialResults)

  return useMemo((): RowGroupConfig[] => {
    return [
      // Revenue
      [
        {
          label: 'Revenue',
          amountTypeFn: (financial) => financial.revenue?.amountType,
          formatFn: formatAmount,
          valueFn: (financial) => financial.revenue?.amount,
        },
        {
          label: 'Growth',
          formatFn: formatGrowth,
          valueFn: (financial) => financial.revenueYoyGrowthPct,
        },
      ],

      // Gross margin
      [
        {
          label: 'Gross margin',
          amountTypeFn: (financial) => financial.grossMargin?.amountType,
          formatFn: formatAmount,
          valueFn: (financial) => financial.grossMargin?.amount,
        },
        {
          label: '% of revenue',
          amountTypeFn: (financial) => financial.grossMargin?.amountType,
          formatFn: formatPercentageOfRevenue,
          valueFn: (financial) => financial.grossMargin?.pctRevenue,
        },
      ],

      // EBITDA
      [
        {
          label: 'EBITDA',
          amountTypeFn: (financial) => financial.ebitda?.amountType,
          formatFn: formatAmount,
          valueFn: (financial) => financial.ebitda?.amount,
        },
        {
          label: '% of revenue',
          amountTypeFn: (financial) => financial.ebitda?.amountType,
          formatFn: formatPercentageOfRevenue,
          valueFn: (financial) => financial.ebitda?.pctRevenue,
        },
      ],

      // EBIT
      [
        {
          label: 'EBIT',
          amountTypeFn: (financial) => financial.ebit?.amountType,
          formatFn: formatAmount,
          valueFn: (financial) => financial.ebit?.amount,
        },
        {
          label: '% of revenue',
          amountTypeFn: (financial) => financial.ebit?.amountType,
          formatFn: formatPercentageOfRevenue,
          valueFn: (financial) => financial.ebit?.pctRevenue,
        },
      ],

      // Consolidated net income
      [
        {
          label: 'Consolidated net income',
          amountTypeFn: (financial) => financial.consolidatedNetIncome?.amountType,
          formatFn: formatAmount,
          valueFn: (financial) => financial.consolidatedNetIncome?.amount,
          labelLetterSpacing: -0.3,
        },
        {
          label: 'Growth %',
          formatFn: formatGrowth,
          valueFn: (financial) => financial.consolidatedNetIncomeYoyGrowthPct,
        },
      ],

      // Earnings per share
      [
        {
          label: 'Total EPS',
          amountTypeFn: (financial) => financial.earningsPerShare?.amountType,
          formatFn: (value) => formatNumber(value, { round: 2 }),
          valueFn: (financial) => financial.earningsPerShare?.amount,
        },
        {
          label: 'Growth %',
          formatFn: formatGrowth,
          valueFn: (financial) => financial.earningsPerShareYoyGrowthPct,
        },
      ],

      // Capex
      [
        {
          label: 'CAPEX',
          amountTypeFn: (financial) => financial.capex?.amountType,
          formatFn: formatAmount,
          valueFn: (financial) => financial.capex?.amount,
          thickBorders: true,
        },
      ],

      // Free cash flow
      [
        {
          label: 'Free cash flow',
          amountTypeFn: (financial) => financial.freeCashFlow?.amountType,
          formatFn: formatAmount,
          valueFn: (financial) => financial.freeCashFlow?.amount,
        },
        {
          label: 'Growth %',
          formatFn: formatGrowth,
          valueFn: (financial) => financial.freeCashFlowYoyGrowthPct,
        },
      ],

      // Cash conversion cycle
      [
        {
          label: 'Cash conversion cycle',
          formatFn: formatAmount,
          valueFn: (financial) => financial.cashConversionCycle?.amount,
        },
      ],

      // Total assets
      [
        {
          label: 'Total assets',
          amountTypeFn: (financial) => financial.totalAssets?.amountType,
          formatFn: formatAmount,
          valueFn: (financial) => financial.totalAssets?.amount,
          thickBorders: true,
        },
      ],

      // Net debt
      [
        {
          label: 'Net debt',
          amountTypeFn: (financial) => financial.netDebt?.amountType,
          formatFn: formatAmount,
          valueFn: (financial) => financial.netDebt?.amount,
          thickBorders: !used.hasTotalAssets,
        },
        {
          label: 'Interest-bearing debt',
          amountTypeFn: (financial) => financial.debt?.amountType,
          formatFn: formatAmount,
          valueFn: (financial) => financial.debt?.amount,
        },
        {
          label: 'Cash & cash equivalents',
          amountTypeFn: (financial) => financial.cash?.amountType,
          formatFn: formatAmount,
          valueFn: (financial) => financial.cash?.amount,
        },
      ],

      // Capital
      [
        {
          label: 'Trade working capital',
          amountTypeFn: (financial) => financial.capital?.amountType,
          formatFn: formatAmount,
          valueFn: (financial) => financial.capital?.amount,
          thickBorders: !used.hasTotalAssets && !used.hasNetDebt,
        },
        {
          label: 'Inventory',
          amountTypeFn: (financial) => financial.inventory?.amountType,
          formatFn: formatAmount,
          valueFn: (financial) => financial.inventory?.amount,
        },
        {
          label: 'Receivables',
          amountTypeFn: (financial) => financial.receivables?.amountType,
          formatFn: formatAmount,
          valueFn: (financial) => financial.receivables?.amount,
        },
        {
          label: 'Payables',
          amountTypeFn: (financial) => financial.payables?.amountType,
          formatFn: formatAmount,
          valueFn: (financial) => financial.payables?.amount,
        },
      ],

      // FTE
      [
        {
          label: 'FTEs',
          amountTypeFn: (financial) => financial.fte?.amountType,
          formatFn: formatFte,
          valueFn: (financial) => financial.fte?.amount,
          thickBorders: true,
        },
        {
          label: 'Growth',
          formatFn: formatGrowth,
          valueFn: (financial) => financial.fteGrowthPct?.amount,
        },
      ],
    ]
  }, [used])
}

interface FinancialsTableProps {
  currency: CurrencyListItem
  financials: FinancialResult[]
}

export default function FinancialsTable({ currency, financials }: FinancialsTableProps) {
  const groups = useRowGroups(financials)
  const lastReportedFinancialYearIndex = useLastReportedFinancialYearIndex(financials)
  const usedAmountTyped = useDefinedAmountTypes(financials)

  return (
    <FinancialResultsTable
      currency={currency}
      financialResults={financials}
      hasAIEstimates={usedAmountTyped.hasAIEstimates}
      hasEstimates={usedAmountTyped.hasEstimates}
      hasForecasts={usedAmountTyped.hasForecasts}
      rightColumnIndex={lastReportedFinancialYearIndex}
      rowGroups={groups}
    />
  )
}
