import { UserProfile } from '@gain/rpc/app-model'
import { isNotDefined } from '@gain/utils/common'
import { useEffect, useRef } from 'react'

// IMPORTANT! The HubSpot integration is used to serve the NPS survey to users.
// We are in the process of migrating this to Planhat. In the meantime the
// HubSpot integration needs to maintain active.

declare global {
  interface Window {
    _hsq: unknown[] | undefined
  }
}

/**
 * Push a command to the HubSpot command queue.
 *
 * See: https://developers.hubspot.com/docs/api/events/tracking-code
 */
function pushCommand(command: unknown) {
  window._hsq = window._hsq || []
  window._hsq.push(command)
}

interface Identity {
  email: string
  id: string
  firstname: string
  lastname: string
}

/**
 * Identify the current user in HubSpot
 */
export default function useIdentifyHubspot(profile: UserProfile | null | undefined) {
  const previousIdentityRef = useRef<Identity | null>()

  useEffect(() => {
    if (isNotDefined(profile)) {
      return
    }

    // Convert user profile to HubSpot compatible identity
    const identity: Identity = {
      email: profile.email,
      id: profile.intercomHmac,
      firstname: profile.firstName,
      lastname: profile.lastName,
    }

    // Avoid tracking duplicates
    if (JSON.stringify(identity) === JSON.stringify(previousIdentityRef.current)) {
      return
    }

    // Identify the user (see: https://developers.hubspot.com/docs/api/events/tracking-code#identify-a-visitor)
    pushCommand(['identify', identity])

    // Send identity to HubSpot by pushing a trackPageView command
    // (see: https://developers.hubspot.com/docs/api/events/tracking-code#track-page-view)
    pushCommand(['trackPageView'])

    previousIdentityRef.current = identity
  }, [profile])
}
