import { ChevronRightIcon } from '@gain/components/icons'
import Typography from '@gain/components/typography'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { ReactElement } from 'react'

import InfoButton from '../../info-button'

const StyledRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  minWidth: 0,
})

const StyledMetricValueContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
  flexWrap: 'wrap',
  minWidth: 0,
}))

const StyledLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  minWidth: 0,
  alignItems: 'center',
  color: theme.palette.text.primary,
  ...theme.typography.h5,
}))

const StyledValue = styled('span')(({ theme }) => ({
  color: theme.palette.text.primary,
  ...theme.typography.h5,
}))

const StyledChevronRightIcon = styled(ChevronRightIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 18,
}))

export interface MetricProps {
  label: string
  value: string | number
  href?: string | false
  explainer?: string
  suffixComponent?: ReactElement
}

export default function Metric({ label, value, href, explainer, suffixComponent }: MetricProps) {
  return (
    <StyledRoot>
      <Stack
        alignItems={'center'}
        direction={'row'}
        gap={0.75}
        minWidth={0}>
        <Typography
          color={'text.secondary'}
          variant={'body2'}
          noWrap>
          {label}
        </Typography>
        {explainer && (
          <InfoButton
            dialogMessage={explainer}
            dialogTitle={label}
          />
        )}
      </Stack>
      <StyledMetricValueContainer>
        {!!href && (
          <StyledLink href={href}>
            <Typography
              color={'text.primary'}
              variant={'h5'}
              noWrap>
              {value}
            </Typography>
            <StyledChevronRightIcon />
          </StyledLink>
        )}
        {!href && <StyledValue>{value}</StyledValue>}
        {suffixComponent}
      </StyledMetricValueContainer>
    </StyledRoot>
  )
}
