import { useMemo } from 'react'

export default function useCheckboxTooltip(totalSize: number, max: number, isAllSelected: boolean) {
  return useMemo(() => {
    if (totalSize < max) {
      return ''
    }

    if (!isAllSelected) {
      return `Select first ${max}`
    }
    return 'Deselect all'
  }, [totalSize, max, isAllSelected])
}
